<template>
  <div class="page-box">
    <div class="summary">
      <div class="item">
        <div class="name">余额（元）</div>
        <div class="value">{{summary.amount}}</div>
      </div>
      <div class="item expand">
        <div class="name">已用优惠券数量</div>
        <div class="value">{{summary.usedNum}}</div>
      </div>
      <div class="item expand">
        <div class="name">已用优惠券总额（元）</div>
        <div class="value">{{summary.usedAmount}}</div>
      </div>
      <a-button type="primary" size="small" shape="circle" icon="sync" :loading="summaryLoading" @click="fetchSummary"></a-button>
    </div>
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="变动类型" prop="changeType">
          <a-select allow-clear v-model="queryRef.changeType" placeholder="请选择" style="min-width: 140px">
            <a-select-option v-for="item in changeTypeOptions" :key="item.value" :value="item.value">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="订单编号" prop="orderNo">
          <a-input allow-clear v-model="queryRef.orderNo" placeholder="请输入" />
        </a-form-model-item>
        <br />
        <a-form-model-item label="时间" prop="dateRange">
          <a-range-picker v-model="queryRef.dateRange" />
          <a-radio-group v-model="quickRefer" button-style="solid" style="margin-left: 15px;">
            <a-radio-button value="today">
              今
            </a-radio-button>
            <a-radio-button value="yestoday">
              昨
            </a-radio-button>
            <a-radio-button value="7">
              近7天
            </a-radio-button>
            <a-radio-button value="30">
              近30天
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>

    <div class="btn-box">
      <a-button
        type="primary"
        @click="exportFn"
        :loading="exporting"
      >导出</a-button>
    </div>

    <base-table
      bordered
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :rowKey="record => record.shopCouponAccountBillId"
      :loading="loadingRef"
      @change="handleTableChange"
    >
    </base-table>
  </div>
</template>

<script>
import { capital } from '@/api'
import { ref, reactive, toRefs, onMounted, computed } from '@vue/composition-api'
import { changeTypeOptions, rechargeTypeOptions } from '@/utils/constants'
import NP from 'number-precision'
import moment from 'moment'
import { genMapFromList, excludeFields, useExport } from '@/utils/tools'

const columns = [
  {
    title: '操作时间',
    dataIndex: 'createTime'
  },
  {
    title: '支付方式',
    dataIndex: 'rechargeType',
    width: '90px',
    customRender: x => genMapFromList(rechargeTypeOptions)[x]
  },
  {
    title: '变动金额（元）',
    dataIndex: 'changeAmount',
    width: '140px'
  },
  {
    title: '订单编号',
    dataIndex: 'orderNo'
  },
  {
    title: '余额（元）',
    dataIndex: 'amount',
    width: '140px'
  },
  {
    title: '操作',
    dataIndex: 'changeType',
    customRender: x => genMapFromList(changeTypeOptions)[x],
    width: '100px'
  }
]

export default {
  name: 'PageCashCouponRemain',
  setup (props, { root }) {
    const formRef = ref(null)
    const state = reactive({
      queryRef: {
        changeType: undefined,
        dateRange: [],
        orderNo: ''
      },
      tableData: [],
      loadingRef: false,
      pagination: {
        current: 1,
        size: 10,
        total: 0
      }
    })
    const today = moment().startOf('day')
    const yestoday = moment().startOf('day').subtract(1, 'day')
    const seven = moment().startOf('day').subtract(1, 'week')
    const thirty = moment().startOf('day').subtract(1, 'month')
    const quickRefer = computed({
      get: () => {
        let a, b
        if ((a = state.queryRef.dateRange[0]) && (b = state.queryRef.dateRange[1])) {
          if (a.isSame(today) && b.isSame(today)) {
            return 'today'
          }
          if (a.isSame(yestoday) && b.isSame(yestoday)) {
            return 'yestoday'
          }
          if (a.isSame(seven) && b.isSame(today)) {
            return '7'
          }
          if (a.isSame(thirty) && b.isSame(today)) {
            return '30'
          }
          return ''
        } else {
          return ''
        }
      },
      set: (value) => {
        switch (value) {
          case 'today':
            state.queryRef.dateRange = [today, today]
            break
          case 'yestoday':
            state.queryRef.dateRange = [yestoday, yestoday]
            break
          case '7':
            state.queryRef.dateRange = [seven, today]
            break
          case '30':
            state.queryRef.dateRange = [thirty, today]
            break
          default: throw new Error('unknonw value')
        }
      }
    })
    onMounted(() => {
      getTableData()
    })
    function handleSearch () {
      state.pagination.current = 1
      getTableData()
    }

    function handleReset () {
      formRef.value.resetFields()
      state.pagination.current = 1
      getTableData()
    }

    function handleTableChange ({ current }) {
      state.pagination.current = current
      getTableData()
    }
    function createQuery () {
      return {
        current: state.pagination.current,
        size: state.pagination.size,
        ...excludeFields(state.queryRef, ['dateRange']),
        startTime: state.queryRef.dateRange[0]
          ? state.queryRef.dateRange[0].format('YYYY-MM-DD HH:mm:ss')
          : '',
        endTime: state.queryRef.dateRange[1]
          ? moment(state.queryRef.dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
          : ''
      }
    }
    async function getTableData () {
      state.loadingRef = true
      const { data, msg, code, page } = await capital.getCashCouponFlowHistoryPage(createQuery())
      state.loadingRef = false

      if (code === '00000') {
        state.tableData = data
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }

    const summary = ref({})
    const summaryLoading = ref(false)
    async function fetchSummary () {
      summaryLoading.value = true
      const { data } = await capital.getCashCouponSummary()
      summaryLoading.value = false
      summary.value = data
    }
    onMounted(fetchSummary)

    const { exportFn, exporting } = useExport(() => capital.exportCashCouponFlowHistory(createQuery()))

    return {
      ...toRefs(state),
      changeTypeOptions,
      columns,
      formRef,
      handleSearch,
      handleReset,
      handleTableChange,
      getTableData,
      quickRefer,
      NP,
      fetchSummary,
      summary,
      summaryLoading,
      exportFn,
      exporting
    }
  }
}
</script>

<style lang="less" scoped>
.btn-box {
  margin-bottom: 18px;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
.shop-addr {
  margin-bottom: 0;
}
.submit-form {
  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
}
.mini {
  font-size: 10px;
  color: #999;
}
.mini.red {
  color: #dc0024;
}
.summary {
  display: flex;
  padding-bottom: 20px;
}
.summary .item {
  min-width: 200px;
  & .name {
    font-size: 14px;
    color: #666;
  }
  & .value {
    font-size: 20px;
    padding-top: 10px;
  }

  &.expand {
    flex: 1 0 auto;
  }
}
</style>
